<template>
  <!-- 所属企业  -->
  <div>
    <div class="Ptit">
      <strong class="tit">所属企业</strong>
      <span class="rset">
        <el-button v-if="showEnditBtn" size="small" @click="edit">
          编辑企业资料
        </el-button>
        <el-button v-if="showEnditBtn" size="small" @click="shenhe">
          企业成员
        </el-button>
      </span>
    </div>
    <div v-if="zjd === 1">
      <div class="seaEnterprise">
        <el-input
          v-model="enterName.qName"
          @input="enterNameChange"
          placeholder="输入企业名称"
        >
          <template #append>
            <el-button type="primary" icon="el-icon-search" />
          </template>
        </el-input>
      </div>
      <div v-if="enterName.qName !== ''">
        <div class="qyList" v-if="enterList.length > 0">
          <div>
            <el-table :data="enterList" style="width: 100%">
              <el-table-column prop="qName" label="企业名称" />
              <el-table-column prop="fieldLabel" label="类型" width="100" />
              <el-table-column prop="urbanAreas" label="所在区域" />
              <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="toApplyFor(scope.row)"
                    >申请加入</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else class="zwqyLis">
          <el-result
            icon="warning"
            title="暂无该企业信息"
            sub-title="您是否要创建该企业信息？"
          >
            <template #extra>
              <el-button type="primary" @click="zjd = 2"
                >创建企业信息</el-button
              >
            </template>
          </el-result>
        </div>
      </div>
    </div>
    <div v-else-if="zjd === 2">
      <!-- 添加企业 -->
      <div class="usHomeFormBox">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="21vw"
          class="usHome-ruleForm"
          :size="formSize"
        >
          <el-form-item label="企业名称" prop="qName">
            <el-input v-model="ruleForm.qName" />
          </el-form-item>
          <el-form-item label="所属行业" prop="type">
            <el-select
              v-model="ruleForm.type"
              class="m-2"
              placeholder="选择行业"
              size="large"
            >
              <el-option
                v-for="(iam, iax) in hangye"
                :key="iax"
                :label="iam.fieldLabel"
                :value="iam.fieldValue"
              />
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="统一社会信用代码" prop="qCode">
            <el-input v-model="ruleForm.qCode" />
          </el-form-item>
          <el-form-item label="法人姓名" prop="bossName">
            <el-input v-model="ruleForm.bossName" />
          </el-form-item>
          <el-form-item label="法人身份证号" prop="bossNameID">
            <el-input v-model="ruleForm.bossNameID" />
          </el-form-item> -->
          <el-form-item label="所在区域">
            <el-cascader
              v-model="quyu"
              :options="comOption"
              :show-all-levels="false"
              @change="shengshiqu"
            />
          </el-form-item>
          <!-- <el-form-item label="经营地址">
            <el-input v-model="ruleForm.officeAddress" />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="ruleForm.phone" />
          </el-form-item> -->
          <el-form-item label="营业执照" prop="businessLicense">
            <div v-if="userIdImgA === ''">
              <el-upload
                class="upload-demo"
                action=""
                :before-upload="uploadsA"
                multiple
                :limit="1"
                :file-list="fileList"
                style="display:flex;"
              >
                <el-button>上传文件</el-button>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left:12px;">
                    jpg/png 格式，文件大小不超过 500KB.
                  </div>
                </template>
              </el-upload>
            </div>
            <div v-else>
              <img
                style="max-width:500px;max-height:380px;"
                :src="userIdImgA"
              />
            </div>
          </el-form-item>
          <el-form-item label="法人持身份证照片" prop="bossImgId">
            <div v-if="userIdImgB === ''">
              <el-upload
                class="upload-demo"
                action=""
                :before-upload="uploadsB"
                multiple
                :limit="1"
                :file-list="fileListB"
                style="display:flex;"
              >
                <el-button>上传文件</el-button>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left:12px;">
                    jpg/png 格式，文件大小不超过 500KB.
                  </div>
                </template>
              </el-upload>
            </div>
            <div v-else>
              <img
                style="max-width:500px;max-height:380px;"
                :src="userIdImgB"
              />
            </div>
          </el-form-item>

          <el-form-item label="企业介绍">
            <el-input :rows="5" type="textarea" v-model="ruleForm.content" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)">
              提交资料
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else-if="zjd === 3">
      <div>
        <el-result
          icon="success"
          title="所属企业添加成功!"
          sub-title="后续加入该企业的人员需要您审核"
        >
          <template #extra>
            <el-button type="primary" @click="goPage('/usindex')"
              >确定</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
    <div v-else-if="zjd === 4">
      <!-- 查看自己关联或创建的企业 -->
      <div class="ptBx2 formTopJG">
        <div class="pnimg">
          <div class="fxcBox">
            <template
              v-if="qyImg === '' || qyImg === 'http://admin.qdwq.cc/null'"
            >
              暂未上传营业执照
            </template>
            <template v-else>
              <img :src="qyImg" style="width:90%" />
            </template>
          </div>
        </div>
        <div class="ptcont ">
          <div class="form">
            <el-descriptions class="whgDescriptions" :column="1">
              <el-descriptions-item label="企业名称">
                {{ qyObj.qName }}
              </el-descriptions-item>
              <el-descriptions-item label="所属行业">
                {{ qyObj.fieldLabel }}
              </el-descriptions-item>
              <el-descriptions-item label="统一社会信用代码">
                {{ qyObj.qCode }}
              </el-descriptions-item>
              <el-descriptions-item label="所在区域">
                {{ qyObj.urbanAreas }}
              </el-descriptions-item>
              <el-descriptions-item label="经营地址" :span="2">
                {{ qyObj.officeAddress }}
              </el-descriptions-item>
              <el-descriptions-item label="状态">
                <template v-if="qyObj.comSH === 1"
                  ><strong class="FsZ1">未审核</strong></template
                >
                <template v-else-if="qyObj.comSH === 2">已通过</template>
                <template v-else-if="qyObj.comSH === 3"
                  ><span class="FsHs">已拒绝</span></template
                >
                <template v-else>正常</template>
              </el-descriptions-item>
              <el-descriptions-item label="企业介绍">
                {{ qyObj.content }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="zjd === 5">
      <!-- 审核别人的申请 -->
      <div>
        <!-- <h3>企业成员</h3> -->
        <div>
          <el-table :data="shenheList" style="width: 100%">
            <el-table-column label="头像" width="60">
              <template #default="scope">
                <img class="tx" :src="scope.row.photo" /> </template
            ></el-table-column>
            <el-table-column
              prop="name"
              label="昵称"
              width="180"
            ></el-table-column>
            <el-table-column label="性别">
              <template #default="scope">
                <span v-if="scope.row.sex === '1'">男</span>
                <span v-else-if="scope.row.sex === '2'">女</span>
                <span v-else>保密</span>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" width="160" />
            <el-table-column label="实名认证">
              <template #default="scope">
                <span v-if="scope.row.certification === 0">未认证</span>
                <span v-else-if="scope.row.certification === 1"
                  >提交未审核</span
                >
                <span v-else-if="scope.row.certification === 2">
                  审核不通过
                </span>
                <span v-else-if="scope.row.certification === 3">审核通过</span>
                <span v-else></span>
              </template>
            </el-table-column>
            <el-table-column label="审核状态">
              <template #default="scope">
                <span v-if="scope.row.comSH === 1" class="FsHs">未审核</span>
                <span v-else-if="scope.row.comSH === 0">创建者</span>
                <span v-else-if="scope.row.comSH === 2">已通过</span>
                <span v-else-if="scope.row.comSH === 3">拒绝</span>
                <span v-else></span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template #default="scope">
                <div v-if="scope.row.comSH === 1">
                  <el-button
                    type="text"
                    size="small"
                    @click="shenheQy(scope.row, 'ok')"
                    >通过</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="shenheQy(scope.row, 'jj')"
                    >拒绝</el-button
                  >
                </div>
                <div v-else>
                  <el-button
                    type="text"
                    size="small"
                    @click="shenheDel(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="shenheQue.total"
            @current-change="paginationPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  getUserInfo,
  uploadImg,
  getSeachEnterpriseName,
  getAddEnterprise,
  getLookEnterprise,
  getauditEnterpriseList,
  toApplyForEnterprise,
  toApplyForEnterpriseShenhe
} from '@/api/user'
import tools from '@/utils/tools'
import { getItem, sign, setItem, toUsId } from '@/utils/storage' //  getItem, toUsId
import { ElMessage, ElMessageBox } from 'element-plus'
import { dictionary } from '@/api/sys'
import { commitOption } from './js/enterprise'
import { useRouter } from 'vue-router'
const router = useRouter()
const zjd = ref(1) // 总进度
if (getItem('userinfo').comID !== null) {
  zjd.value = 4
}
const showEnditBtn = ref(false)

// 查询企业
const enterName = ref({
  qName: '',
  page: 1,
  size: 10,
  total: 0
}) // 搜索企业名
const enterList = ref([])
const enterNameChange = async s => {
  if (s !== '') {
    enterName.value.qName = s
    enterName.value.sjs = Math.ceil(Math.random() * 10)
    delete enterName.value.sign
    console.log(enterName.value)
    var revv = await getSeachEnterpriseName(sign(enterName.value))
    enterList.value = revv.list
  }
}
const toApplyFor = s => {
  console.log('申请加入=', s)
  ElMessageBox.alert('确定要加入' + s.qName + '吗？', '加入企业', {
    confirmButtonText: '确定',
    callback: action => {
      toApplyForFun(s.qyId)
    }
  })
}
// 发送申请 请求
const toApplyForFun = async v => {
  var fhz = await toApplyForEnterprise(sign({ qyId: v }))
  if (fhz === 'ok') {
    ElMessage.info('已申请成功，请耐心等待管理员审核')
    var ius = getItem('userinfo')
    ius.comID = v
    ius.comSH = 1
    setItem('userinfo', ius)
  }
}

// 创建企业  -----  行业
const hangye = ref([])
const hangyeFun = async () => {
  hangye.value = await dictionary(sign({ type: 'industry' }))
}
hangyeFun() // 行业

// 添加企业
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = ref({
  qName: '',
  officeAddress: '', // 经营地址
  qCode: '', // 统一社会信用代码
  bossName: '', // 法人姓名
  bossNameID: '',

  phone: '', // 联系电话
  businessLicense: '', // 营业执照
  bossImgId: '', // 法人持身份证照片
  urbanAreas: '', // 省市区三级
  type: '',
  content: ''
})

const rules = {
  qName: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { min: 2, max: 40, message: '2-40个字符', trigger: 'blur' }
  ],
  qCode: [
    { required: true, message: '统一社会信用代码不能为空', trigger: 'blur' },
    { min: 16, max: 20, message: '为18个字符', trigger: 'blur' }
  ],
  type: [
    {
      required: true,
      message: '请选择类型',
      trigger: 'change'
    }
  ],
  businessLicense: [
    { required: true, message: '请上传营业执照照片', trigger: 'blur' }
  ],
  bossImgId: [
    { required: true, message: '请上传法人持身份证照片', trigger: 'blur' }
  ],
  bossName: [{ required: true, message: '法人姓名不能为空', trigger: 'blur' }],
  bossNameID: [
    { required: true, message: '法人身份证号不能为空', trigger: 'blur' }
  ]
}
// 提交
const submitForm = async formEl => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      ruleForm.value.tg = 'ok'
      tools.basic.filterEmptyData(ruleForm.value)
      postCertificationFun(ruleForm.value)
    } else {
      console.log('提交失败', fields)
    }
  })
}
const postCertificationFun = async data => {
  console.log(data)
  var revv = await getAddEnterprise(sign(data))
  if (revv) {
    var ius = getItem('userinfo')
    ius.comID = revv
    ius.comSH = 0
    setItem('userinfo', ius)
    zjd.value = 3
  }
}
const userIdImgA = ref('')
const userIdImgB = ref('')
const uploadsA = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  ruleForm.value.businessLicense = revv
  userIdImgA.value = window.g.baseURL + revv
  console.log('ruleForm.value--', ruleForm.value)
}
const uploadsB = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  ruleForm.value.bossImgId = revv
  userIdImgB.value = window.g.baseURL + revv
  console.log('ruleForm.value--', ruleForm.value)
}
const quyu = ref([])
const shengshiqu = s => {
  ruleForm.value.urbanAreas = s.join('-')
}
const comOption = commitOption()
const goPage = ss => {
  router.push(ss)
}

const qyObj = ref({})
const qyImg = ref('')
const bossImgId = ref('')
const getLookEnterpriseFun = async () => {
  var iqy = await getLookEnterprise(sign({ qyId: getItem('userinfo').comID }))
  if (
    iqy.businessLicense !== undefined ||
    iqy.businessLicense !== null ||
    iqy.businessLicense !== ''
  ) {
    qyImg.value = window.g.baseURL + iqy.businessLicense
  }
  if (iqy.bossImgId !== undefined) {
    bossImgId.value = window.g.baseURL + iqy.bossImgId
  }
  var iid = parseInt(toUsId(getItem('token')))

  if (iid === iqy.tuID) {
    showEnditBtn.value = true
  }
  qyObj.value = iqy
}
if (zjd.value === 4) {
  getLookEnterpriseFun()
}
const edit = () => {
  // console.log('ss', qyObj.value.bossImgId)
  // console.log('urbanAreas', qyObj.value.urbanAreas)
  ruleForm.value = {
    qName: qyObj.value.qName,
    officeAddress: qyObj.value.officeAddress, // 经营地址
    qCode: qyObj.value.qCode, // 统一社会信用代码
    bossName: qyObj.value.bossName, // 法人姓名
    bossNameID: qyObj.value.bossNameID,
    phone: qyObj.value.phone, // 联系电话
    businessLicense: qyObj.value.businessLicense, // 营业执照
    bossImgId: qyObj.value.bossImgId,
    urbanAreas: qyObj.value.urbanAreas, // 省市区三级
    type: qyObj.value.type,
    content: qyObj.value.content
  }
  if (qyObj.value.urbanAreas !== null) {
    quyu.value = qyObj.value.urbanAreas.split('-')
  }
  if (qyObj.value.businessLicense !== null) {
    userIdImgA.value = qyImg.value
  }
  if (qyObj.value.bossImgId !== null) {
    userIdImgB.value = bossImgId.value
  }

  zjd.value = 2
}
const shenhe = () => {
  shenheLisFun()
  zjd.value = 5
}
// 需要审核的申请
const shenheList = ref([])
const shenheQue = ref({
  qyId: getItem('userinfo').comID,
  page: 1,
  size: 10
})
const shenheLisFun = async () => {
  var ifhz = await getauditEnterpriseList(sign(shenheQue.value))
  ifhz.list.forEach((els, elx) => {
    var inm = els.photo.slice(0, 4) // uplo
    els.inx = elx
    if (inm !== 'http') {
      els.photo = window.g.baseURL + els.photo
    }
  })
  shenheList.value = ifhz.list
  shenheQue.value.total = ifhz.total
}
// 审核申请
const shenheQy = async (iobj, izt, inx) => {
  var jg = await toApplyForEnterpriseShenhe(
    sign({ ID: iobj.ID, zt: izt, comID: iobj.comID })
  )
  if (jg === 'ok') {
    if (izt === 'ok') {
      iobj.comSH = 2
    } else if (izt === 'jj') {
      iobj.comSH = 3
    } else {
      shenheList.value.splice(iobj.inx, 1)
    }
  } else {
    ElMessage.error('操作失败')
  }
}
const shenheDel = iobj => {
  ElMessageBox.confirm(
    '确定要删除成员 “' + iobj.name + '” 吗？',
    '删除企业成员',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      shenheQy(iobj, 'del')
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消删除'
      })
    })
}

const paginationPage = v => {
  shenheQue.value.page = v
  shenheLisFun()
}

const getUserInfos = async () => {
  var res = await getUserInfo(
    sign({ userinfo: 'sjs', sjs: Math.ceil(Math.random() * 10) })
  )
  console.log(res)
  if (res.comID === null || res.comSH === 3) {
    zjd.value = 1
  }
  // 完成
  setItem('userinfo', res)
}
getUserInfos()
</script>

<style lang="scss" scoped>
.seaEnterprise {
  text-align: center;
  border-bottom: solid 1px #ddd;
  padding: 20px 10vw;
}
.zwqyLis {
  padding-top: 40px;
}
img.tx {
  width: 32px;
  height: 32px;
}
</style>
